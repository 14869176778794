<template>
    <div class="d-flex flex-wrap justify- align-items-center" style="gap:10px">
        <a v-for="item in datas" :key="item.socmed_id" target="_blank" :href="item.socmed_url">
            <img :src="item.socmed_logo" style="min-width:25px;max-width:50px;min-height:25px;max-height:50px;" class="flex-fill" />
        </a>
    </div>
</template>


<script>
import store from '@/store'

export default {
    data(){
        return {
            datas : []
        }
    },
    props: {
        type: {
            default : 'fullcolor',
            type: String
        }
    },
    async mounted(){
        console.log('asdsa')
        this.datas = await store.dispatch('page_content/getSocialMedias', { type: this.type })
    }
}
</script>