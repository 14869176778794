<template>
  <b-nav-item-dropdown
    id="lang-menu"
    variant="link"
    class="dropdown-language my-auto"
    right
    v-if="languages && currentLocale"
  > 
    <template #button-content>
      <b-img
        :src="currentLocale.lang_img"
        height="14px"
        width="22px"
        :alt="currentLocale.lang_code"
      />
      <span class="ml-50 text-body font-weight-bolder">{{ currentLocale.lang_name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in languages"
      :key="localeObj.lang_id"
      @click="changeLanguage(localeObj.lang_code)"
    >
      <b-img
        :src="localeObj.lang_img"
        height="14px"
        width="22px"
        :alt="localeObj.lang_code"
      />
      <span class="ml-50">{{ localeObj.lang_name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.languages?.find(l => l.lang_code === 
        this.$store.state.locales.CURRENT)
    },
    languages(){
      return this.$store.state.locales.DT 
    }
  },
  methods: {
    changeLanguage(locale) {
      this.$store.dispatch('locales/changeLanguage', locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'EN',
        img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg/2560px-Flag_of_Great_Britain_%281707%E2%80%931800%29.svg.png',
        name: 'English',
      }, 
      {
        locale: 'INA',
        img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/255px-Flag_of_Indonesia.svg.png',
        name: 'Indonesia  ',
      }, 
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>
  .dropdown-language a {
    display: flex;
    align-items: center;
  }

  #lang-menu {
    padding: 0.786rem 1.5rem;
  }
</style>
