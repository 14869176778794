<template>
  <!-- <div> -->
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
      :style="
        `z-index:999px;top:0;${transparent
          ? 'background-color:transparent !important'
          : 'background-color:#E8F1F8 !important'}; 
          `
      "
    >  
      <div class="container">
        <b-navbar-brand href="/"
          ><img src="@/static/assets/logo-color.png" style="max-width: 60px"
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav> </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a class="nav-link" aria-current="page">
              <router-link to="/" class="btn btn-flat-primary">Home</router-link>
            </a>
            <a class="nav-link">
              <b-dropdown
                id="dropdown-grouped"
                variant="flat-dark"
                text="Products & Services" 
                class="dropdown-icon-wrapper"
              >
                <div class="p-2 d-flex" style="gap: 20px;border-radius:16px" id="procat-main">
                  
                </div>
              </b-dropdown>
            </a>
            <a class="nav-link" aria-current="page">
              <router-link to="/insight" class="btn btn-flat-primary"
                >Insight</router-link
              >
            </a>
            <a class="nav-link">
              <router-link to="/events" class="btn btn-flat-primary">Events</router-link>
            </a>
            <!-- <a class="nav-link">
              <router-link to="/about-us" class="btn btn-flat-primary"
                >About Us</router-link
              >
            </a> -->
             <a class="nav-link">
              <b-dropdown
                id="dropdown-grouped"
                variant="flat-dark"
                text="About Us" 
                class="dropdown-icon-wrapper"
              >
                <b-dropdown-item href="/about-us#ABS-2">
                  <strong>Our Company</strong>
                </b-dropdown-item>
                <b-dropdown-item href="/team">
                  <strong>Our Team</strong>
                </b-dropdown-item>
                <!-- <b-dropdown-item href="/about-us#ABS-9">
                  <strong>Our Clients</strong>
                </b-dropdown-item> -->
              </b-dropdown>
            </a>
            <locale />
            <a class="nav-link">
              <router-link
                to="/contacts"
                class="rounded-pill shadow-lg fab-help d-flex justify-content-center"
                id="contact"
              >
                GET IN TOUCH
              </router-link>
            </a>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  <!-- </div> -->
</template>

<style >
a {
  color: rgba(34, 41, 47, 0.5);
  font-weight: bold;
}

.router-link-exact-active {
  color: #0B5087 !important;
}

.btn {
  color: #000 !important;
  font-weight: bolder;
}

#dropdown-grouped {
  display: flex;
  flex-direction: column;
}

#dropdown-grouped button {
  flex-direction: column;
  color: #000 !important;
}

#procat-main{
  display: flex;
  width: 95vw;
  flex-direction: column;
  padding: 1.5rem;
}

#contact {
  cursor: pointer;
  background: #ef6d22;
  color: white !important;
  margin: 0 1.5rem;
  padding: 0.65em 1.5em;
}

@media (min-width: 992px) {
  #dropdown-grouped {
    display: block;
  }

  #procat-main {
    flex-direction: row;
    position: absolute;
    transform: translateX(-200px);
    background-color: #fff;
    width: max-content;
  }

  #contact {
    margin: 0;
  }
}
</style>

<style>


</style>

<script>
import {
  BDropdown, BDropdownItem,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavItem,
  BNavbarNav,
} from "bootstrap-vue";

import Locale from "./Locale.vue";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default {
  props: {
    transparent: Boolean,
    absolute: Boolean
  },
  components: {
    Locale,
    BDropdown, BDropdownItem,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavItem,
    BNavbarNav,
  },
  async mounted() {
    if (!cookies.get("lang")) {
      cookies.set("lang", "EN", { path: "/" });
    } else {
      this.$store.dispatch("locales/changeLanguage", cookies.get("lang"));
    }
    this.$store.dispatch("locales/getAllLang");

    $("#procat-main").empty();

    const cat = await this.$store.dispatch("product_categories/getDataList");
    cat
      .filter((item) => item.procat_level == "1")
      .map((item) => {
        $("#procat-main").append(`
        <div>
          <h3 class="h4 font-weight-bolder text-center mb-2" style="color:#0B5087">
            ${item.procat_name}
          </h3>
          <div style="display: flex; flex-wrap: wrap; width: 100%; gap: 20px" id="procat-${item.procat_id}">

          </div>
        </div>
      `);
      });
    cat
      .filter((item) => item.procat_level == "2")
      .map((item) => {
        $(`#procat-${item.procat_parent}`).append(`
        <div class="flex-fill" style="width:250px">
          <h3 class="h6 font-weight-bolder" style="color:#0B5087 !important;max-width:300px">
            ${item.procat_name}
          </h3>
          <div style="background: #ef6d22; height: 1px"></div>
          <div class="mt-2" id="procat-${item.procat_id}">
          
          </div>
        </div>
      `);
      });
    cat
      .filter((item) => item.procat_level == "3")
      .map((item) => {
        let url = `/product-services/${item.procat_id}`
        if(item.procat_is_leaf == 'yes'){
          url = `/product-services/${item.procat_layout_type}/${item.procat_id}`
        } else if(item.procat_redirect){
          url = item.procat_redirect
        } 
        $(`#procat-${item.procat_parent}`).append(`
        <div class="flex-fill">
          <a href="${url}"> 
            <h3 class="h6 font-weight text-wrap">
              ${item.procat_name}
            </h3>
          </a>
        </div>
      `);
      });
  },
};
</script>
