<template>
  <div class="ql-editor p-0">
    <div class="px-5 position-relative" style="background:#0B5087"> 
      <div class="container py-5" style="color: white !important">
        <div class="row">
          <div class="col-lg-4">
            <img src="@/static/assets/logo-light.png" />
            <div id="FOT-1">
            </div>
          </div>
          <div class="col-lg-4">
            <h3 class="mb-2   " id="FOT-2">About Us</h3>
            <a href="/about-us#ABS-2">
              <h6 class="my-1  " id="FOT-3">Our Company</h6>
            </a>
            <!-- <h6 class="my-1 text-white">Our Products & Services</h6> -->
            <a href="/team">
              <h6 class="my-1  " id="FOT-4">Our Teams</h6>
            </a>
            <a href="/about-us#ABS-9">
              <h6 class="my-1  " id="FOT-5">Our Clients</h6>
            </a>
          </div>
          <div class="col-lg-4">
            <h3 class="mb-2  " id="FOT-6">Reach Us</h3>
            <h6 class="my-1  d-flex align-items-center" id="FOT-PHONE"><i class="bx bx-phone mr-1"></i> {{company.whatsapp}}</h6>
            <h6 class="my-1   d-flex align-items-center" id="FOT-EMAIL"><i class="bx bx-envelope mr-1"></i> {{company.email}}</h6>
            <h3 class="mt-3   mb-1" id="FOT-7">Social Media</h3>
            <social-medias :type="'light'" />
          </div>
        </div>
      </div>
    </div>
    <div class="" style=" background:#08365A">
      <div class="container text-center p-2">
        <span class="" id="FOT-8">Copyright @ {{(new Date()).getFullYear()}}. {{company.name}} </span>
      </div>
    </div>
  </div>
</template>

<script>

import company from '../static/config/company.json'
import SocialMedias from '@/components/SocialMedias.vue'
import store from '@/store'

export default {
  components: {
    SocialMedias
  },
  data(){
    return {
      company
    }
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  mounted(){
      this.getNextDatas()
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  methods: {
    async getNextDatas(){
      let temp = await store.dispatch(`page_content/getDataList`, {
          lang_code: this.currentLocale,
          code: 'FOT',  
      }) 
      temp?.map((item, key) => {
        document.getElementById(item.code).innerHTML = item.content
      })
      this.$forceUpdate()
    },
  },
}
</script>
