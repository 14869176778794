<template>
  <div class="position-relative" style="z-index:999999999">
    <div class="card shadow-lg position-fixed" style="bottom:20px;right:10px;max-width:400px; " v-if="isOpen">
        <div class="rounded-lg text-white px-2 py-1" style="min-width:100px;background:#FF9F43">
          Human Techno Support
        </div>
      <div class="card-body">
        <div class="d-flex" style="gap:10px">
          <img src="@/static/assets/logo-color.png" style="height:50px;width:50px;object-fit:contain" />
          <p style="background:#F3F3F3" class="p-1 rounded-lg">
            Halo! Terima kasih sudah menghubungi HumanTechno.

            Silakan sampaikan kebutuhan Anda.
          </p>
        </div>

        <div class="my-5">
        </div>

        <div class="d-flex" style="gap:10px">
          <input class="form-control" v-model="message" placeholder="Sampaikan kebutuhan anda">
          <button class="send-wa btn text-white" style="background:#FF9F43" @click="send()" >
            <i class="bx bx-send text-white"></i>
          </button>
        </div>

      </div>
    </div>
    <div class="position-fixed" style="bottom:10px;right:10px;z-index:999999px">
      <div @click="openModal()" style="cursor:pointer" class="px-2 rounded-pill shadow-lg py-1 fab-help d-flex align-items-center">
        <i class="bx bx-message-dots" style="font-size:18px;margin-right:.5em"></i>
        Contact Us Via WA
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    openModal() {
      this.isOpen = !this.isOpen
    },
    send(){
      this.openModal()
      // let urlencode = encodeURIComponent(btoa(this.message));
      window.location.href = `https://wa.me/6285728888108?text=${this.message}`
      this.message = ''
    }
  },
  data(){
    return {
      isOpen: false,
      message: ''
    }
  }
}
</script>
